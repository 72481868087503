





















































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import store from '@/services/store';


@Component({
    components: {}
})
export default class CClarifyAddModal extends Vue {
    @Prop({
        required: true,
        default: false
    })
    private show!: boolean;
    @Prop({
        required: false,
        default: "b5bbb151-d502-4ff7-b87d-95f44c6e8d26"
    })
    private variant!: string;
    @Prop({
        required: true,
        default: 2022
    })
    private year!: number;
    @Prop({
        required: false,
        default: -1
    })
    private budget_level!: number;
    private is_loading_bip = false;

    private showModal = false;

    // ----------------------budgetLevel-----------------
    private budgetLevel: number[] = [];

    @Watch("budget_level")
    private async loadBudgetLevel() {
        this.budgetLevel = [];
        if (this.budget_level<0) {
            if (!this.usrId) {
                return;
            }
            let result = [];
            try {
                result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки уровня бюджета', (error as Error).toString());
                return;
            }
            for (const el of result) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const indx = el.budget_level.lastIndexOf('_');
                if (indx >= 0) {
                    this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
                }
            }
            if (this.budgetLevel.length === 0) {
                this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!');
            }
        } else {
            this.budgetLevel.push(this.budget_level)
        }
        this.loadAbp();
        this.loadSpf();
    }

    private get is_ready_to_add() {
        if (this.curProg !== null) {
            if (this.curProg.develop_type === 1) {
                if (this.curBip === null) {
                    return false;
                }
            }
        }
        if (this.subProg.length>0) {
            return this.curSubProg!==null;
        }
        return true;
    }

    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;


    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) {
            this.curAbp = this.setNameLang(this.curAbp, 'abp');
        }
        return res;
    }

    private chgAbp() {
        this.loadProg();
    }

    private async loadAbp() {
        if (!this.budgetLevel || this.budgetLevel.length < 1) {
            return;
        }
        const url = `/api-py/get-dict-ved-abp-by-budgetlevel/${encodeURI(JSON.stringify(this.budgetLevel))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
            } else {
                this.makeToast('danger', 'Ошибка загрузки адм. программ', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
        }

        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
        this.chgAbp();
    }

    // ----------------Программа
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) {
            this.curProg = this.setNameLang(this.curProg, 'prg');
        }
        return res;
    }

    private clear_bip() {
        this.bipBase = [];
        this.curBip = null;
    }

    private chgProg() {
        this.clear_bip();
        this.loadSubProg();
    }

    private chgSubProg() {
        this.clear_bip();
    }

    private chgSpf() {
        this.clear_bip();
        this.loadBip()
    }

    private async loadProg() {
        let response: any = [];
        this.progBase = [];
        if (response.length === 0 && this.curAbp !== null) {
            try {
                const params = {abp: this.curAbp.abp, type: 4};
                response = await fetch(`/api-py/get-dict-func-childs-by-parent-code/${encodeURI(JSON.stringify(params))}`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', (error as Error).toString());
            }
        }

        response.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
        this.progBase = this.setIdArr(response, 'prg');

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
        } else {
            this.curProg = null;
        }
        this.chgProg();
    }

    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];
        if (this.curProg !== null) {
            try {
                const params = {abp: this.curAbp.abp, prg: this.curProg.prg, type: 5};
                response = await fetch(`/api-py/get-dict-func-childs-by-parent-code/${encodeURI(JSON.stringify(params))}`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
                response = [];
            }
        }

        response.sort((a: any, b: any) => (a.ppr - b.ppr > 0) ? 1 : -1);
        // eslint-disable-next-line @typescript-eslint/camelcase
        // if (response.length) {
        //     response.unshift({name_ru: 'Все'});
        // }
        this.subProgBase = this.setIdArr(response, 'ppr');

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
        } else {
            this.curSubProg = null;
        }
    }

    // --- spf
    private spfBase: any[] = [];
    private curSpf: any | null = null;

    private get spfDict(): any[] {
        const result: any[] = [];
        for (const el of this.spfBase) {
            result.push(this.setNameLang(el, 'spf'));
        }
        return result;
    }

    private async loadSpf() {
        this.spfBase = [];
        let response: any = [];
        this.curSpf = null;
        try {
            response = await fetch(`/api-py/dict-ebk-spf-date-null/`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
            response = [];
        }
        this.spfBase = response;
        if (this.spfDict.length) {
            this.curSpf = this.setNameLang(this.spfBase[0], 'spf');
        }
    }

    // --- spf
    private bipBase: any[] = [];
    private curBip: any | null = null;

    private get bipDict(): any[] {
        const result: any[] = [];
        for (const el of this.bipBase) {
            result.push(this.setNameLang(el, 'bip_code'));
        }
        return result;
    }

    private async loadBip() {
        this.bipBase = [];
        const request = {
            variant: this.variant,
            abp: this.curAbp.abp,
            prg: this.curProg.prg,
            ppr: this.curSubProg ? this.curSubProg.ppr : undefined,
            spf: this.curSpf.spf,
            year: this.year
        }
        let response: any = [];
        this.curBip = null;
        try {
            this.is_loading_bip = true;
            response = await fetch(`/api-py/get-bip-list/`, {
                method: 'POST',
                body: JSON.stringify(request)
            });
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки бип', (error as Error).toString());
            response = [];
            this.is_loading_bip = false;
        }
        this.bipBase = response;
        this.is_loading_bip = false;
        if (this.bipDict.length) {
            this.curBip = this.setNameLang(this.bipBase[0], 'bip_code');
        }
    }

    // ---------------------------------------------

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    mounted() {
        this.loadBudgetLevel();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
                if (that.abpBase.length === 0) {
                    that.loadBudgetLevel();
                }
            }
            that.$emit('shown');
        });
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({idArr: [el.id]}, el));
            }
        }
        return result;
    }

    private addClk() {
        if (this.curSubProg && this.curSubProg.ppr === undefined) {
            for (const el of this.subProg) {
                if (el.ppr !== undefined) {
                    const result = {
                        abp: this.curAbp,
                        prog: this.curProg,
                        subProg: el,
                        spf: this.curSpf,
                        bip: this.curBip
                    };
                    this.$emit('addClk', result);
                }
            }
        } else {
            const result = {
                abp: this.curAbp,
                prog: this.curProg,
                subProg: this.curSubProg,
                spf: this.curSpf,
                bip: this.curBip
            };
            this.$emit('addClk', result);
        }
        this.showModal = false;
    }
}
